<template>
  <div
    v-if="activeUser && activeUser.currentRole"
    id="dashboard-analytics"
  >
    <div v-if="activeUser.currentRole.name != 'patient'">
      <DashboardUser />
    </div>
    <div v-else>
      <DashboardPatient />
    </div>
  </div>
  <div v-else />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    DashboardUser: () => import('./DashboardUser.vue'),
    DashboardPatient: () => import('./DashboardPatient.vue'),
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('auth', [
      'activeUser',
    ]),
  },
}
</script>
